.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 0px 10px
}

.logo {
    text-decoration: none;
    font-size: 25px;
    font-weight: 700;
    color: white;
}

.privacy {
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    color: white
}